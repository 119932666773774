<template>
    <b-container fluid>
        <b-row v-if="associates">
            <b-col>
                <h1>Kartica Suradnika</h1>
                <b-tabs>
                    <b-tab title="Dobavljači" active>
                        <osta-table position
                                    :items="associates.suppliers"
                                    :fields="associatesFields"
                                    @rowClicked="goToAssociateCard">
                        </osta-table>
                    </b-tab>
                    <b-tab title="Kupci">
                        <osta-table position
                                    :items="associates.buyers"
                                    :fields="associatesFields"
                                    @rowClicked="goToAssociateCard">
                        </osta-table>
                    </b-tab>
                </b-tabs>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    export default {
        name: 'associate-cards',
        data() {
            return {
                associatesFields: [
                    {
                        key:'code',
                        label: 'Konto',
                        sortable: true
                    },
                    {
                        key: 'name',
                        label: 'Ime',
                        sortable: true
                    },
                    {
                        key: 'city',
                        label: 'Grad',
                        sortable: true
                    },
                    {
                        key: 'id_number',
                        label: 'ID broj',
                        sortable: false
                    },
                    {
                        key: 'pdv_number',
                        label: 'PDV broj',
                        sortable: false,
                        formatter: (value) => (value) ? value : 'Nije u sustavu'
                    }
                ]
            }
        },
        computed: {
            associates: function () {
                return this.$store.getters.associates;
            }
        },
        methods: {
            goToAssociateCard(id) {
                this.$router.push('/associate_cards/' + id);
            },
        }
    }
</script>